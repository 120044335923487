.body{
    font-family: 'Abril Fatface', cursive;
    color: #ECDBBA;
}

h1{
    font-family: 'IBM Plex Serif',serif;
    color: #ece7dc;
}

.par{
    font-family: 'IBM Plex Serif',serif;
    color: #ece7dc;
    font-size: 30px;
    border-bottom: 1px solid #008F11;
}

.par2{
    font-family: 'IBM Plex Serif', serif;
    color: #ece7dc;
    font-size: 30px;
}
.par3{
    color: #008F11;
    font-family: 'VT323', monospace;
    font-size: 21px;
    margin-bottom: 25px;
}

.div1{
    justify-content: center;
    display: flex;
    margin: 50px auto;
    padding: 15px;
    width: 70%;
}

.div2{  
    margin: 15px;
    padding: 10px;
    width: auto;
}

.image{
    height: auto;
    width: 60%;
    float: left;
    margin: 20px;
    border-radius: 10px;
}
.images{
    height: auto;
    width: 60%;
    float: left;
    margin: 20px;
    border-radius: 10px;
}
.image2{
    height: 100px;
    width: 100px;
    float: left;
    margin: 20px;
    border-radius: 10px;
}

table {
    width: 800px;
    height: auto;
}
tr{
    display: flex;
    justify-content: space-evenly;
}
th{
    align-self: center;
}


.card{
            background-color: "#003B00";
            margin: "0 20px 0 20px";
            width: 220px;
            cursor: pointer;
            
}

@media (max-width : 850px){
    .abt{
        flex-direction: column;
    }
    .images{
        width: 30%;
    }
}