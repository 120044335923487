$background: #040303;
$base: #e6e8e7;
$screen: #0D0208;
$frame: #3f3f41;
$open: #919191;
$base2: #d1d2d4;

//mixin
@mixin align-horizontal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

html,
body {
    background: $background;
}

.container {
    width: 90%;
    margin: 0 auto;
    padding: 2em;
    justify-content: center;
    display: flex;
}

h1 {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    color: rgba(#fff, 0.85);
    margin: 0 0 1em 0;
    font-size: 22px;

    span {
        font-size: 16px;
        display: block;
        color: rgba(#fff, 0.75);
        margin: 5px 0 0 0;
        font-style: italic;
    }
}

p{
    color: #008F11;
    font-family: 'VT323',monospace;
    font-size: 21px;
}

//the magic
.laptop {
    position: relative;
    width: 650px;
    height: 400px;
    margin: 0 auto;
    background: $screen;
    border: 15px solid $frame;
    border-top: 20px solid $frame;
    border-radius: 14px 14px 0 0;
    margin: 0 0 90px 0;
    box-shadow: 0px 10px 36px -2px rgba(216, 204, 204, 0.2);
    padding: 10px;
    // box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);

    &:before {
        content: '';
        bottom: -35px;
        background: $base;
        height: 20px;
        width: 800px;
        border-radius: 0 0 10px 10px;
        @include align-horizontal;
    }

    &:after {
        content: '';
        top: -12px;
        width: 3px;
        height: 3px;
        background: $base;
        border-radius: 50%;
        @include align-horizontal;
    }
}

//base to make this better
.base {
    bottom: -25px;
    background: $base2;
    height: 10px;
    width: 800px;
    @include align-horizontal;

    &:before {
        content: '';
        border-radius: 0 0 10px 10px;
        height: 10px;
        width: 80px;
        background: $open;
        @include align-horizontal;
    }

    &:after {
        content: '';
        position: absolute;
        height: 20px;
        width: 800px;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 10px 36px -2px rgba(255, 255, 255, 0.2);
    }
}

@media (max-width : 850px) {
    body{
        width: fit-content;
    }
}